import React, { memo, useEffect } from "react"
import { graphql, useStaticQuery , navigate } from "gatsby"


import GettingStartedHero from "images/illustrations/getting-started-hero.svg"
import UsingLatanaHero from "images/illustrations/using-latana-hero.svg"
import WhatIsLatanaHero from "images/illustrations/what-is-latana-hero.svg"

const query = graphql`
	query {
		allContentfulFaqPost(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					title
					slug
					category
					content {
						content
					}
					seo {
						keywords
					}
				}
			}
		}
		contentfulPage(slug: { eq: "faq" }) {
			hideFooterDemo
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
			}
		}
	}
`

const categoryImages = new Map()
categoryImages.set("what-is-latana", WhatIsLatanaHero)
categoryImages.set("getting-started", GettingStartedHero)
categoryImages.set("using-latana", UsingLatanaHero)

const FAQ = () => {
	const { allContentfulFaqPost } = useStaticQuery(query)
	const faqPosts = allContentfulFaqPost.edges
	
	useEffect(() => {
		navigate(`/faq/${faqPosts[0]?.node?.slug}`)
	}, [])

	return (
		<div />
	)
}

export default memo(FAQ)
